.home {
  display: flex;
  flex-direction: row;

  .el-dropdown-menu {
    min-width: 120px;
    text-align: left;
  }

  .iconblock {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    background-color: rgb(24, 48, 70);
    box-shadow: 0 0 15px rgba(4, 4, 4, 0.3);
    width: 60px;
    height: 100vh;
    z-index: 1;

    .navitop {
      display: flex;
      flex-direction: column;
    }

    .navibot {
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }
  }

  .main {
    display: flex;
    flex-basis: 100%;
    height: 100vh;
    overflow-y: scroll;
    text-align: center;
    justify-content: center;
  }
}
