.project {
  width: 100%;
  display: flex;
  flex-direction: row;

  .nav-bar {
    max-width: 150px;
    height: 100%;
    position: fixed;
    height: 100%;
    .project-menu {
      display: inline-block;
      text-align: left;
      height: 100%;
      width: 100%;
    }
  }
  .project-main {
    width: calc(100% - 180px);
    display: flex;
    justify-content: center;
    text-align: left;
    margin: 10px;
    margin-left: 180px;
    .main-container {
      width: 500px;
      margin-top: 100px;
      .el-input__inner {
        color: #1f2d3db3;
      }
      .el-form--label-top .el-form-item__label {
        color: #053e69;
        font-weight: bold;
        font-size: 13px;
      }
      .heading .el-form-item__content {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #053e69;
      }
      .sub-title {
        font-weight: bold;
        color: #053e69;
      }
      .description {
        font-size: 13px;
      }
      .el-button--primary {
        min-width: 120px;
      }
    }
    .test-block .el-table--fit {
      overflow: visible;
    }
    .test-block .table-data {
      display: inline-block;
      width: 100%;
      margin-bottom: 50px;
    }
    .login-form {
      margin-top: 100px;
    }
    .keytext-form {
      margin-top: 50px;
      width: 500px;
    }

    .status {
      display: flex;
      margin: 0;

      .statusleft {
        display: flex;
        flex-direction: row;
      }

      .statusright {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        justify-content: center;
      }
    }
    .deploy {
      input[type="file"] {
        display: none;
      }
      .partner-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
      }
    }
  }
}
